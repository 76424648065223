
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = [
      {
        product: {
          image: "/media/stock/600x400/img-26.jpg",
          name: "Sant Extreanet Solution",
          specs: "HTML, JS, ReactJS",
        },
        price: "$2,790",
        deposit: "$520",
        agent: {
          name: "Bradly Beal",
          position: "Insurance",
        },
        status: {
          label: "Approved",
          color: "primary",
        },
      },
      {
        product: {
          image: "/media/stock/600x400/img-3.jpg",
          name: "Telegram Development",
          specs: "C#, ASP.NET, MS SQL",
        },
        price: "$4,790",
        deposit: "$240",
        agent: {
          name: "Chris Thompson",
          position: "NBA Player",
        },
        status: {
          label: "In Progress",
          color: "danger",
        },
      },
      {
        product: {
          image: "/media/stock/600x400/img-9.jpg",
          name: "Payroll Application",
          specs: "PHP, Laravel, VueJS",
        },
        price: "$4,390",
        deposit: "$593",
        agent: {
          name: "Zoey McGee",
          position: "Ruby Developer",
        },
        status: {
          label: "Success",
          color: "success",
        },
      },
      {
        product: {
          image: "/media/stock/600x400/img-18.jpg",
          name: "HR Management System",
          specs: "Python, PostgreSQL, ReactJS",
        },
        price: "$7,990",
        deposit: "$980",
        agent: {
          name: "Brandon Ingram",
          position: "Insurance",
        },
        status: {
          label: "Rejected",
          color: "info",
        },
      },
      {
        product: {
          image: "/media/stock/600x400/img-8.jpg",
          name: "Telegram Mobile",
          specs: "HTML, JS, ReactJS",
        },
        price: "$5,790",
        deposit: "$750",
        agent: {
          name: "Natali Trump",
          position: "Insurance",
        },
        status: {
          label: "Approved",
          color: "warning",
        },
      },
    ];

    return {
      list,
    };
  },
});
